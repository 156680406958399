// i18next-extract-mark-ns-start products-sabr-dcahp

import { SABR_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductSabrPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							SABR can assess multiple load path regimes defined by
							time, torque and speed inputs. The seamless set-up of
							complex load paths, including dual clutch and hybrids,
							across any number of gear sets can be modelled and the
							analysis includes effects of any back driven components.
						</p>
						<p>
							The method of data input is straightforward with a
							graphical representation of the transmission clearly
							showing the proposed load path from any number of power
							sources. &nbsp;The duty cycle and gear geometries can be
							exported to GEAR to allow representative gear design and
							life optimisation.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Ability to enter as many loading regimes as required
							</li>
							<li>
								Simple graphical interface enabling precise and fast
								data entry
							</li>
							<li>Screen click activated load path definition</li>
							<li>
								Hybrid power flows with any number of power sources
							</li>
							<li>Constraints-based speed and torque calculation</li>
							<li>Direct duty cycle export with gear geometry</li>
							<li>Duty cycle import from .csv format</li>
							<li>
								Duty cycle compression into smaller number of load
								and speed bins
							</li>
						</ul>
					</Trans>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{SABR_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductSabrPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-sabr-dcahp", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/sabr-hero.jpg" }) {
		...imageBreaker
	}
}
`;
